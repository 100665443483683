import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import ExperienceTimeline from "../components/ExperienceTimelineComponent";
export interface IAboutPageProps { }

const AboutPage: React.FC<IAboutPageProps> = () => {
    return <Box sx={{ marginTop: 2, marginBottom: 6 }}>
        <Box display='flex' justifyContent='center' flexDirection="column">
            <Typography align="center" sx={{ marginX: { xs: 8, md: 30 }, marginTop: 4 }} variant="h5" alignContent='center' fontWeight="bold" fontFamily='inherit'>
                Clifton Strengths
            </Typography>
            <Typography align="center" sx={{ marginX: { xs: 8, md: 30 } }} variant="body1" alignContent='center' fontFamily='inherit'>
                1. Achiever 2. Woo 3. Responsibility 4. Positivity 5. Arranger
            </Typography>

            <Typography align="center" sx={{ marginX: { xs: 8, md: 30 }, marginTop: 4 }} variant="h5" alignContent='center' fontWeight="bold" fontFamily='inherit'>
                Skills
            </Typography>
            <Typography align="center" sx={{ marginX: { xs: 8, md: 30 } }} variant="body1" alignContent='center' fontFamily='inherit'>
                Kotlin, Coroutines, Flows, LiveData, KMM, Java, RxJava3, WorkManager, Dagger2, Hilt, Koin, Kodein, Magnet, Ktor, Retrofit,
                OkHttp3, Moshi, Gson, BLE, Room, Realm, SQLDelight, GreenDAO, SQLite, Firebase Crashlytics, Firebase Analytics,
                Firebase App Distribution, Firebase Cloud Messaging, Google Maps, HERE Maps, Open Street Maps, Timber,
                Stetho, Glide, Picasso, JUnit, Mockito, MockK, Espresso, Robolectric, AWS S3, Material Design, CanaryLeaks, Play Store, UmbrellaSDK
            </Typography>

            <Typography align="center" sx={{ marginX: { xs: 8, md: 30 }, marginTop: 4 }} variant="h5" alignContent='center' fontWeight="bold" fontFamily='inherit'>
                Experience
            </Typography>
            <ExperienceTimeline />

            <Typography align="center" sx={{ marginX: { xs: 8, md: 30 }, marginTop: 2 }} variant="h5" fontWeight="bold" alignContent='center' fontFamily='inherit'>
                Education
            </Typography>
            <Typography align="center" sx={{ marginX: { xs: 8, md: 30 } }} variant="body1" alignContent='center' fontFamily='inherit'>
                Tutor at University of Technology, Wroclaw, faculty - Computer Science and Management,<br />field of teaching - Mobile Systems
            </Typography>
            <Typography align="center" sx={{ marginX: { xs: 8, md: 30 } }} variant="body1" alignContent='center' fontFamily='inherit'>
                Masters degree at University of Technology, Wroclaw, faculty - Computer Science and Management,<br />field of study - Computer Science, speciality - Design of IT Systems
            </Typography>
            <Typography align="center" sx={{ marginX: { xs: 8, md: 30 } }} variant="body1" alignContent='center' fontFamily='inherit' whiteSpace="pre-line">
                Engineer degree at University of Technology, Wroclaw, faculty - Computer Science and Management,<br />field of study - Computer Science
            </Typography>

            <Typography align="center" sx={{ marginX: { xs: 8, md: 30 }, marginTop: 4 }} variant="h5" fontWeight="bold" alignContent='center' fontFamily='inherit'>
                Languages
            </Typography>
            <Typography align="center" sx={{ marginX: { xs: 8, md: 30 } }} variant="body1" alignContent='center' fontFamily='inherit'>
                English B2+
            </Typography>
            <Typography align="center" sx={{ marginX: { xs: 8, md: 30 } }} variant="body1" alignContent='center' fontFamily='inherit'>
                German A2
            </Typography>
        </Box>
    </Box>
};

export default AboutPage;