import './App.css';
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import HomePage from './pages/Home';
import BlogPage from './pages/Blog';
import ResponsiveAppBar from './components/ResponsiveAppBar';
import ProjectsPage from './pages/Projects';
import AboutPage from './pages/About';

export interface IApplicationProps { }

const App: React.FunctionComponent<IApplicationProps> = (props) => {
  return (
    <BrowserRouter>
      <ResponsiveAppBar></ResponsiveAppBar>
      <Routes>
        <Route path='/' element={<HomePage />}></Route>
        <Route path='projects' element={<ProjectsPage />}></Route>
        <Route path='about' element={<AboutPage />}></Route>
        <Route path='blog' element={<BlogPage />}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
