import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import PetsIcon from '@mui/icons-material/Pets';
import TruckIcon from '@mui/icons-material/LocalShipping';
import PointIcon from '@mui/icons-material/PointOfSale';
import TvIcon from '@mui/icons-material/Tv';
import HealthIcon from '@mui/icons-material/HealthAndSafety';
import EnergyIcon from '@mui/icons-material/EnergySavingsLeaf';
import FlagIcon from '@mui/icons-material/Flag';
import Typography from '@mui/material/Typography';
import React from 'react';

const ExperienceTimeline = () => {
    return (
        <Timeline position="alternate">
               <TimelineItem>
                <TimelineOppositeContent sx={{ m: 'auto 0' }} variant="body2" color="black">
                    11.2022
                </TimelineOppositeContent>
                <TimelineSeparator>
                    <TimelineConnector />
                    <TimelineDot color="grey">
                        <PointIcon />
                    </TimelineDot>
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent sx={{ py: '12px', px: 2 }}>
                    <Typography variant="h6" component="span">
                        <b>PAYBACK</b>, Munich, DE
                    </Typography>
                    <Typography>Software Engineer</Typography>
                    <Typography>Technical Owner in Wallet Team</Typography>
                </TimelineContent>
            </TimelineItem>
            <TimelineItem>
                <TimelineOppositeContent sx={{ m: 'auto 0' }} variant="body2" color="black">
                    05.2021
                </TimelineOppositeContent>
                <TimelineSeparator>
                    <TimelineConnector />
                    <TimelineDot color="grey">
                        <PetsIcon />
                    </TimelineDot>
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent sx={{ py: '12px', px: 2 }}>
                    <Typography variant="h6" component="span">
                        <b>DOOK</b>, Wroclaw, PL
                    </Typography>
                    <Typography>Senior Android Developer</Typography>
                    <Typography> Mobile Guild Leader</Typography>
                    <Typography> Team Leader</Typography>
                </TimelineContent>
            </TimelineItem>
            <TimelineItem>
                <TimelineOppositeContent sx={{ m: 'auto 0' }} variant="body2" color="black">
                    10.2020
                </TimelineOppositeContent>
                <TimelineSeparator>
                    <TimelineConnector />
                    <TimelineDot color="grey">
                        <TruckIcon />
                    </TimelineDot>
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent sx={{ py: '12px', px: 2 }}>
                    <Typography variant="h6" component="span">
                        <b>Fireup.pro</b>, Rybnik, PL
                    </Typography>
                    <Typography>Senior Android Developer</Typography>
                </TimelineContent>
            </TimelineItem>
            <TimelineItem>
                <TimelineOppositeContent sx={{ m: 'auto 0' }} variant="body2" color="black">
                    02.2020
                </TimelineOppositeContent>
                <TimelineSeparator>
                    <TimelineConnector />
                    <TimelineDot color="grey">
                        <TvIcon />
                    </TimelineDot>
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent sx={{ py: '12px', px: 2 }}>
                    <Typography variant="h6" component="span">
                        <b>Better Software Group</b>, Wroclaw, PL
                    </Typography>
                    <Typography>Android TV Developer</Typography>
                </TimelineContent>
            </TimelineItem>
            <TimelineItem>
                <TimelineOppositeContent sx={{ m: 'auto 0' }} variant="body2" color="black">
                    10.2019
                </TimelineOppositeContent>
                <TimelineSeparator>
                    <TimelineConnector />
                    <TimelineDot color="grey">
                        <HealthIcon />
                    </TimelineDot>
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent sx={{ py: '12px', px: 2 }}>
                    <Typography variant="h6" component="span">
                        <b>Axxiome Health</b>, Wroclaw, PL
                    </Typography>
                    <Typography>Technical Consultant</Typography>
                </TimelineContent>
            </TimelineItem>
            <TimelineItem>
                <TimelineOppositeContent sx={{ m: 'auto 0' }} variant="body2" color="black">
                    06.2017
                </TimelineOppositeContent>
                <TimelineSeparator>
                    <TimelineConnector />
                    <TimelineDot color="grey">
                        <EnergyIcon />
                    </TimelineDot>
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent sx={{ py: '12px', px: 2 }}>
                    <Typography variant="h6" component="span">
                        <b>Capgemini</b>, Wroclaw PL
                    </Typography>
                    <Typography>Software Engineer</Typography>
                    <Typography>Junior Software Engineer</Typography>
                </TimelineContent>
            </TimelineItem>
            <TimelineItem>
                <TimelineOppositeContent sx={{ m: 'auto 0' }} variant="body2" color="black">
                    07.2016
                </TimelineOppositeContent>
                <TimelineSeparator>
                    <TimelineConnector />
                    <TimelineDot color="grey">
                        <FlagIcon />
                    </TimelineDot>
                    <TimelineConnector sx={{ bgcolor: 'white' }} />
                </TimelineSeparator>
                <TimelineContent sx={{ py: '12px', px: 2 }}>
                    <Typography variant="h6" component="span">
                        <b>Biostat</b>, Rybnik, PL
                    </Typography>
                    <Typography>Android Developer</Typography>
                    <Typography>Internship</Typography>
                </TimelineContent>
            </TimelineItem>
        </Timeline>
    );
}

export default ExperienceTimeline;
