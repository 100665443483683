import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
import ProjectCard from '../components/ProjectCardComponent';
import ProjectDetails from '../model/ProjectDetails';
export interface IProjectsPageProps { }

const projects = [
    new ProjectDetails('Pure Dependency Injection', 'Refactor of dirty app to show how DI simplify code. Pure Dependency Injection without 3rd part libraries implemented there.', 'https://images.unsplash.com/photo-1588597575483-f4e43e191c94?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2133&q=80', 'https://github.com/dawidpiechaczek/pure-dependency-injection'),
    new ProjectDetails('Multimodular app', 'Example architecture to show how I approach building structure of newly created apps.', 'https://images.unsplash.com/photo-1559322575-2f4e66131d55?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80', 'https://github.com/dawidpiechaczek/multi-modular-app'),
    new ProjectDetails('Photo launcher', 'Tool capturing photos from camera and gallery.', 'https://images.unsplash.com/photo-1510127034890-ba27508e9f1c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80', 'https://github.com/dawidpiechaczek/photo-launcher')
];

const ProjectsPage: React.FC<IProjectsPageProps> = () => {
    return <Box sx={{ marginX: { xs: 6, md: 30 }, marginTop: { xs: 3, md: 6 }, justifyContent: 'center' }}>
        <Grid container rowSpacing={8} columnSpacing={{ xs: 1, sm: 4, md: 8 }}>
            {projects.map((project) => (
                <Grid xs={12} md={6}>
                    <ProjectCard {...project} />
                </Grid>
            ))}
        </Grid>
    </Box>
}

export default ProjectsPage