import React from "react";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";

export interface IBlogPageProps { }

const BlogPage: React.FC<IBlogPageProps> = () => {
    return <Box justifyContent='center' flexDirection='column' sx={{ marginTop: 10, marginX: { xs: 10, md: 20 } }}>
        <Typography align='center' variant="body1" alignContent='center' fontFamily='inherit'>
            Section under construction...
        </Typography>
    </Box>
}

export default BlogPage