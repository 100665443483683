import { Avatar, Button, Divider, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import Logo from "../dawid_piechaczek.jpg"
import { LinkedIn, GitHub, Facebook } from "@mui/icons-material";

export interface IHomePageProps { }

const HomePage: React.FC<IHomePageProps> = () => {
    return <Box>
        <Box display='flex' justifyContent='center'>
            <Avatar
                alt="Avatar" src={Logo} sx={{ marginTop: 4, marginBottom: 4, width: 180, height: 180 }}>
            </Avatar>
        </Box>
        <Box justifyContent='center' flexDirection='column' sx={{ marginX: { xs: 10, md: 20 } }}>
            <Typography align='center' variant="h4" alignContent='center' fontFamily='inherit' fontWeight='bold'>
                Hey, I’m Dawid Piechaczek.
            </Typography>
            <Typography align='center' sx={{ marginTop: 6 }} variant="body1" fontFamily='inherit'>
            I'm a Software Engineer currently shaping the digital future at <b>PAYBACK</b>. Armed with a Master's in Computer Science from Wroclaw University of Technology, <br/>I bring both a solid academic foundation and a passion for practical problem-solving to the world of coding.<br/>
            In my role as a software engineer at <b>PAYBACK</b>, I navigate the dynamic landscape of technology, crafting digital solutions that seamlessly integrate with the ever-evolving needs of our industry. <br/>My work extends beyond mere code; it's about architecting experiences, solving complex puzzles, and building bridges between ideas and execution.
            </Typography>
            <Typography align='center' sx={{ marginTop: 4 }} variant="body1" fontFamily='inherit'>
            In an industry that's in a perpetual state of evolution, I believe in the power of <b>continuous learning</b>. Whether it's the latest frameworks, emerging technologies, or refining existing skills, I relish the opportunity to expand my knowledge.
            </Typography>
            <Typography align='center' sx={{ marginTop: 4, marginBottom: 4 }} variant="body1" fontFamily='inherit'>
                Let's make mobile better. <b>Together</b>.
            </Typography>
            <Divider />
            <Box display='flex' justifyContent='center' sx={{ marginTop: 4, marginBottom: 4 }}>
                <Button key="LinkedIn" href="https://www.linkedin.com/in/dawid-piechaczek" sx={{ my: 2, color: 'black', textTransform: 'none', fontSize: 16 }} startIcon={<LinkedIn />}>LinkedIn</Button>
                <Button key="GitHub" href="https://github.com/dawidpiechaczek" sx={{ my: 2, color: 'black', textTransform: 'none', fontSize: 16, marginX: 6 }} startIcon={<GitHub />}>GitHub</Button>
                <Button key="Facebook" href="https://www.facebook.com/dawid.piechaczek" sx={{ my: 2, color: 'black', textTransform: 'none', fontSize: 16 }} startIcon={<Facebook />}>Facebook</Button>
            </Box>
        </Box>
    </Box>
};

export default HomePage;