import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import ProjectDetails from '../model/ProjectDetails';
import React from 'react';

const ProjectCard = (projectDetails: ProjectDetails) => {
    return (
        <Card sx={{ maxWidth: 500 }}>
            <CardActionArea href={projectDetails.url}>
                <CardMedia
                    component="img"
                    height="180"
                    image={projectDetails.image}
                    alt="green iguana"
                />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                        {projectDetails.title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {projectDetails.description}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
};

export default ProjectCard
