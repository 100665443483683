class ProjectDetails {

    title: string;
    description: string;
    image: string;
    url: string

    constructor(title: string, description: string, image: string, url: string) {
        this.title = title
        this.description = description
        this.image = image
        this.url = url
    }
}

export default ProjectDetails